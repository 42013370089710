<template>
  <div class="pc-header" :class="hideMenu ? 'hide' : ''">
    <div class="flex-center-between main">
      <div class="logo flex-center-start" @click="toPath('index')">
        <img :src="logo || logoUrl" alt="" />
        <span class="app-name">{{ appName }}</span>
      </div>
      <div class="search">
        <el-input
          v-model="keyword"
          :placeholder="$t('zhao-huo-yuan-shang-pin-gong-ying-shang-cai-gou')"
        >
          <template slot="suffix">
            <el-button @click="toSearch">{{ $t('sou-suo') }}</el-button>
          </template>
          <template slot="prefix">
            <i class="el-icon-search"></i>
          </template>
        </el-input>
      </div>
      <div class="userinfo">
        <i class="el-icon-user"></i>
        <span class="label link" v-if="!isLogin" @click="toPath('userLogin')">{{
          $t('deng-lu')
        }}</span>
        <span class="label link" v-else @click="toPath('userInfoDashboard')">{{
          username
        }}</span>
        <span class="label">{{ $t('huo-zhe') }}</span>
        <span
          class="label link"
          v-if="!isLogin"
          @click="toPath('userRegister')"
          >{{ $t('zhu-ce') }}</span
        >
        <span class="label link" v-else @click="logout">{{
          $t('tui-chu')
        }}</span>
        <i class="el-icon-service space link" @click="showChat"></i>
        <i
          class="el-icon-chat-line-round link"
          @click="toPath('userInfoNotice')"
        ></i>
        <div class="lang-box">
          <el-dropdown trigger="click">
            <div class="lang-info link flex-center">
              <img :src="langIcon" alt="" />
              <i
                class="el-icon-caret-bottom"
                style="color: #333; font-size: 14px"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown" class="lang-dropdown">
              <el-dropdown-item v-for="(item, i) in langList" :key="i">
                <div
                  class="flex-center-start lang-menu"
                  @click="changeLang(item)"
                >
                  <img :src="item.icon" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="menu-list flex-center-between" :class="hideMenu ? 'hide' : ''">
      <div
        class="menu"
        v-for="(item, i) in menuList"
        :key="i"
        :class="activeRoute == item.path ? 'active' : ''"
        @click="toLink(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="other-kefu-box" v-if="showOtherKefu">
      <div @click="hideModal" class="action">
        <i class="el-icon-close"></i>
      </div>
      <iframe src="/#/wap/kf" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import { userInfo } from '@/api/user'
import { removeToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  props: {
    hideMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logoUrl: require('@/assets/imgs/logo.png'),
      keyword: '',
      username: '',
      activeRoute: '',
      showOtherKefu: false
    }
  },
  watch: {
    $route() {
      this.routeChange()
    },
    isLogin(val) {
      this.init()
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    },
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    countryList() {
      return this.$store.state.countryList
    },
    menuList() {
      return [
        {
          name: this.$t('shou-ye'),
          path: 'index'
        },
        {
          name: this.$t('fen-lei'),
          path: 'category'
        },
        {
          name: this.$t('shang-pin'),
          path: 'commodity'
        },
        {
          name: this.$t('zhe-kou-shang-pin'),
          path: 'discount'
        },
        {
          name: this.$t('shang-jia-ru-zhu'),
          path: 'register',
          link: true
        },
        {
          name: this.$t('xin-dai-fu-wu'),
          path: 'credit'
        }
      ]
    }
  },
  mounted() {
    this.routeChange()
    this.init()
  },
  methods: {
    getBaseUrl,
    init() {
      if (!this.isLogin) {
        return
      }
      userInfo().then((res) => {
        let data = res.data
        this.username = data.Email || data.Phone
        this.$store.state.username = this.username
        this.$store.state.shareCode = data.InvitationCode
        this.$store.state.avatar = data.Avatar || '1'
      })
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.$store.state.lang = data.key
      this.$store.state.langIcon = data.icon
      this.$store.state.langName = data.name
    },
    routeChange() {
      this.activeRoute = this.$route.name
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toLink(data) {
      if (data.link) {
        if (!this.isLogin) {
          this.$router.push({
            name: 'userLogin'
          })
          return
        }
        let url = `${window.location.origin}/#/${data.path}`
        let a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } else {
        this.$router.push(data.path)
      }
    },
    logout() {
      removeToken()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'index'
      })
    },
    hideModal() {
      this.$store.state.hideCar = false
      this.showOtherKefu = false
    },
    showChat() {
      this.showOtherKefu = true
      this.$store.state.hideCar = true
      return
      let chatContainer = document.createElement('div')
      chatContainer.style.cssText = `
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 500px;
        height: 680px;
        z-index: 9999;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        overflow: hidden;
        background: #fff;
      `

      let chatFrame = document.createElement('iframe')
      chatFrame.src = '/#/wap/kf'
      chatFrame.style.cssText = `
        width: 500px;
        height: 640px;
        border: none;
        transform-origin: top left;
        overflow: hidden;
      `

      let titleBar = document.createElement('div')
      titleBar.innerHTML = this.$t('zai-xian-ke-fu')
      titleBar.style.cssText = `
        padding: 10px;
        background: #f5f5f5;
        border-bottom: 1px solid #eee;
        font-size: 14px;
      `

      let closeBtn = document.createElement('div')
      closeBtn.innerHTML = '×'
      closeBtn.style.cssText = `
        position: absolute;
        right: 10px;
        top: 8px;
        cursor: pointer;
        font-size: 20px;
        color: #666;
      `
      closeBtn.onclick = () => {
        document.body.removeChild(chatContainer)
      }

      chatContainer.appendChild(titleBar)
      chatContainer.appendChild(chatFrame)
      chatContainer.appendChild(closeBtn)
      document.body.appendChild(chatContainer)
    },
    toSearch() {
      let name = this.$route.name
      let keyword = this.$route.query.keyword
      if (name != 'search' || keyword != this.keyword) {
        this.$router.push({
          name: 'search',
          query: {
            keyword: this.keyword
          }
        })
      }
    }
  }
}
</script>