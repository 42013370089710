<template>
  <div class="pc-home">
    <pc-header :hideMenu="hideMenu"></pc-header>
    <div class="credit-page">
      <div class="top-banner">
        <div class="top-info">
          <img :src="logoUrl" alt="" />
          <div class="title">
            {{ $t('chuang-ye-dai-kuan-jie-jue-jie-qian-de-fan-nao') }}
          </div>
          <div class="sub-title">
            {{ $t('ti-gong-chuang-ye-dai-kuan-zi-jin-zhou-zhuan-fu-wu') }}
          </div>
          <div class="info">
            {{
              $t(
                'wei-ni-jie-jue-zi-jin-jin-zhang-wu-chu-jie-qian-bu-hao-yi-si-xiang-peng-you-kai-kou-jie-qian-deng-dai-kuan-nan-ti-quan-cheng-zhuan-ye-ren-yuan-fu-wu-sui-shi-jie-da-ni-de-yi-huo-bu-jie-wo-men-de-mu-biao-shi-rang-mei-yi-bi-dai-kuan-du-tou-ming-hua-rang-ke-hu-kuai-jie-fang-xin-de-shi-yong'
              )
            }}
          </div>
          <div class="btn-box">
            <el-button @click="toPath('loanApply')">{{
              $t('zai-xian-shen-qing')
            }}</el-button>
            <el-button @click="toPath('myLoan')">{{
              $t('wo-de-dai-kuan')
            }}</el-button>
          </div>
        </div>
      </div>
      <div class="page-title">{{ $t('an-quan-wu-you') }}</div>

      <div class="desc-list flex-center-between">
        <div class="desc" v-for="(item, i) in descList" :key="i">
          <img :src="item.icon" alt="" />
          <div class="info">
            <div class="name">{{ item.name }}</div>
            <div class="label">{{ item.desc }}</div>
          </div>
        </div>
      </div>

      <div class="center-banner">
        <div class="about">
          <div class="title">{{ $t('guan-yu-wo-men') }}</div>
          <div class="info">
            {{
              $t(
                'wo-men-zhi-li-yu-dai-kuan-jin-rong-fu-wu-ji-yu-ke-hu-zhi-shang-de-jing-ying-li-nian-yi-jie-jue-ke-hu-zi-jin-zhou-zhuan-wen-ti-wei-shi-ming-gong-si-cong-cheng-li-zhi-jin-yi-bang-zhu-wu-shu-liu-ke-hu-jie-jue-zi-jin-zhou-zhuan-wen-ti'
              )
            }}
          </div>
          <div class="info">
            {{
              $t(
                'e-du-100000-zhi-30000000-li-xi-di-huan-kuan-fang-shi-ling-huo-wu-ren-he-qian-qi-fei-yong-xian-shang-fang-kuan-bu-da-shen-he-dian-hua-xin-xi-bao-mi-dang-tian-fang-kuan'
              )
            }}
          </div>
          <div class="info">
            {{
              $t(
                'bu-xiang-xiang-tong-xue-peng-you-kai-kou-jie-qian-qing-zhao-wo-men'
              )
            }}
          </div>
          <div class="btn flex">
            <div class="btn1 flex-center">
              {{ $t('zhi-li-yu-chuang-ye-zhe-dai-kuan-fu-wu') }}
            </div>
            <div class="btn2 flex-center">
              {{ $t('jie-jue-nin-de-zi-jin-kun-rao') }}
            </div>
          </div>
        </div>
      </div>

      <div class="page-title">
        {{ $t('he-zuo-huo-ban-zai-xian-jie-dai-ping-tai') }}
      </div>

      <div class="company-list flex-wrap">
        <div class="list" v-for="(item, i) in companyList" :key="i">
          <img :src="item.icon" alt="" />
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import pcHeader from '@/components/pcHeader'
import footerBar from '@/components/footer'
export default {
  components: {
    pcHeader,
    footerBar
  },
  data() {
    return {
      hideMenu: false,
      logoUrl: require('@/assets/imgs/credit-logo.png'),

      companyList: [
        {
          icon: require('@/assets/imgs/company1.png')
        },
        {
          icon: require('@/assets/imgs/company2.png')
        },
        {
          icon: require('@/assets/imgs/company3.png')
        },
        {
          icon: require('@/assets/imgs/company4.png')
        },
        {
          icon: require('@/assets/imgs/company5.png')
        },
        {
          icon: require('@/assets/imgs/company6.png')
        },
        {
          icon: require('@/assets/imgs/company7.png')
        },
        {
          icon: require('@/assets/imgs/company8.png')
        },
        {
          icon: require('@/assets/imgs/company9.png')
        },
        {
          icon: require('@/assets/imgs/company10.png')
        },
        {
          icon: require('@/assets/imgs/company11.png')
        },
        {
          icon: require('@/assets/imgs/company12.png')
        },
        {
          icon: require('@/assets/imgs/company13.png')
        }
      ]
    }
  },
  computed: {
    descList() {
      return [
        {
          icon: require('@/assets/imgs/icon-credit1.png'),
          name: this.$t('ling-huoedu'),
          desc: this.$t(
            'e-du-100000-zhi-30000000-li-xi-di-huan-kuan-fang-shi-ling-huo-wu-ren-he-qian-qi-fei-yong-xian-shang-fang-kuan-bu-da-shen-he-dian-hua-xin-xi-bao-mi-dang-tian-fang-kuan-0'
          )
        },
        {
          icon: require('@/assets/imgs/icon-credit2.png'),
          name: this.$t('ji-su-fang-kuan'),
          desc: this.$t(
            'zui-kuai-yi-xiao-shi-tong-guo-shen-he-shen-he-tong-guo-li-ji-xia-kuan'
          )
        },
        {
          icon: require('@/assets/imgs/icon-credit3.png'),
          name: this.$t('an-quan-wu-you-0'),
          desc: this.$t(
            'wu-di-ya-dai-kuan-wu-xu-ya-jin-bu-da-hui-fang-dian-hua-zhe-ben-jiu-shi-yi-jian-si-mi-de-shi'
          )
        }
      ]
    }
  },
  mounted() {
    document.body.addEventListener('scroll', this.pageScroll)
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.pageScroll)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    pageScroll(e) {
      let top = e.target.scrollTop
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (top > 5) {
          this.hideMenu = true
        } else {
          this.hideMenu = false
        }
      }, 100)
      if (top > 100) {
        this.hideMenu = true
      }
    }
  }
}
</script>